import { render, staticRenderFns } from "./purchase-voucher-againts-receipts.vue?vue&type=template&id=246ed7ca&scoped=true"
import script from "./purchase-voucher-againts-receipts.vue?vue&type=script&lang=ts"
export * from "./purchase-voucher-againts-receipts.vue?vue&type=script&lang=ts"
import style0 from "./purchase-voucher-againts-receipts.vue?vue&type=style&index=0&id=246ed7ca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246ed7ca",
  null
  
)

export default component.exports