
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import AccountService from '@/services/account-service';
import BuiltinService from '@/services/buitin-service';
import purchaseServices from '@/services/purchase-services';
import UserManagementService from "@/services/user-management";
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import assetsService from '@/services/assets-services'
import utils from "@/utils/utils";
import PopUp from "@/components/PopUp.vue";

@Component({
  components: {
    Header,
    SidebarPurchase,
    PopUp
  },
})
export default class PurchaseVoucher extends Vue {
  trans: any = {};
  public selected_enquiries: any = {}
  public selected_requests:any={}
  public selected_quotations:any={};
  public selected_orders:any={};
  public selected_receipts:any={}
  receipt_no:any=""
  payload: any = {};
  error:any=""
  supplier_opt: any = []
  purchase_opt: any = []
  product_opt: any = []
  document_file=null
  selectedItemType: string = ''
  itemsOptions: any = [
    { value: null, text: 'Please select Items Type' },
    { value: 'assets', text: 'Assets' },
    { value: 'inventory', text: 'Inventory' },
    { value: 'consumable', text: 'Consumable' },
    { value: 'thirdparty', text: 'Third Party' },
  ]
  transaction_opt = ['5%', '10%', '15%', '25%']
  selected_employees: any = []
  assets_obj: any = []
  assets_type_obj: any = []
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  items: any = [{
    item_master: {},
    uom: '',
    qty: '',
    storage_bin: {},
    desc: '',
    unit_rate: '',
    total_excl_vat: '',
    disc_amount: '',
    tax_amount: '',
    total_amount: '',
    net_amount: '',
    project: {},
    drop_items: {},
    landed_cost: '',
    item_other_cost: '',
    total_item_other_cost: '',
    item_type: '',
    comment: '',
  }];
  charge_deduct_info: any = [
    {
      charges_deduction: '',
      account: {},
      ammount: '',
      charges: '',
      deduction: '',
      project: {},
      comments: '',
    }
  ]
  assets_info: any = [
    {
      code: '',
      title: '',
      asset_class: '',
      asset_type: '',
      registration: '',
      model: '',
      division: '',
      purchase_date: '',
      purchase_amount: '',
      comments: '',
    }
  ]
  other_cost_info: any = [
    {
      expense: {},
      supplier: {},
      calc: '',
      cost: '',
      project: {},
      comments: '',

    }
  ]
  pruchaseOptions: any = [
    { value: null, text: 'Please select Items Type' },
    { value: 'cash', text: 'Cash' },
    { value: 'voucher', text: 'Voucher' },
  ]
  currency_opt: any = []
  mounted() {
    this.retrieve()
  }
  retrieve() {
    purchaseServices.getAllCurencies().then((response) => {
      this.currency_opt = response.data
    })
    assetsService.getAssetClass().then((response) => {
      this.assets_obj = response.data
    })
  }
  getRate(data: any) {
    this.payload['transaction_rate'] = data.rate
    this.payload['transaction_id'] = data.id
  }
  sup_index = 0;
  supplierType = ''
  assetClassId = -1
  assetTypeId = -1

  search_supplier(title: any, index: any, type: string) {
    this.sup_index = index;
    this.supplierType = type
    if (title.length > 2) {
      AccountService.getSupplierAccount(title).then((response) => {
        this.supplier_opt = response.data.result
      }).catch((e) => {
        console.log(e);
      });


    }
    if (title.length == 0) {
      this.supplier_opt = [];
    }
  }

  pur_index = 0;
  purchaseType = ""
  search_purchase(title: any, index: any, type: string) {
    this.pur_index = index;
    this.purchaseType = type
    if (title.length > 2) {
      AccountService.getByTitleMaster(title).then((response) => {
        let allResults = response.data.result;
        // Get only the top 10 results
        this.purchase_opt = allResults.slice(0, 10);
      }).catch((e) => {
        console.log(e);
      });


    }
    if (title.length == 0) {
      this.purchase_opt = [];
    }
  }
  get_data_supl(data: any, type: string) {
    if (type === 'supplier') {
      this.payload.supplier = data.id;
      this.payload.supplier_code = data.code;
      this.payload.supplier_title = data.title;
    }
    else if (type === 'vendor') {
      this.other_cost_info[this.sup_index].supplier = data;

    }



    this.supplier_opt = [];
  }
  get_data_purchase(data: any, type: string) {
    if (type === 'purchase-account') {
      this.payload.purchase_acc = data.id;
      this.payload.purchase_acc_code = data.code;
      this.payload.purchase_acc_title = data.title;
    }
    else if (type === 'charges') {
      this.charge_deduct_info[this.pur_index].account = data;
    }
    else if (type === 'cash-account') {
      this.payload.cash_acc = data.id;
      this.payload.cash_acc_code = data.code;
      this.payload.cash_acc_title = data.title;
    }
    else if (type == "othercost") {
      this.other_cost_info[this.pur_index].expense = data
    }
    this.purchase_opt = [];

  }
  emp_index = 0;
  search_employee(title: any, index: any) {
    this.emp_index = index;
    if (title.length > 2) {
      UserManagementService.get_Employee_by_Title(title)
        .then((res) => {
          this.selected_employees = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.selected_employees = [];
    }
  }
  get_data(data: any) {
    this.payload.executive = data.id;
    this.payload.executive_title = data.title;
    this.selected_employees = [];
  }

  index = -1
  search_product_item(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      productService
        .getByTitle(title)
        .then((response) => {
          this.product_opt = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.product_opt = [];
    }
  }
  get_product_item(data: any) {
    this.items[this.index].item_master = data;
    this.product_opt = [];
    this.index = -1
  }
  project_index = 0
  selected_projects: any = []
  triggerList = ''
  searchProject(title: any, index: any, type: any) {
    this.triggerList = type
    this.project_index = index
    if (title.length > 2) {
      projectService.getByTitle(title).then((response) => {
        this.selected_projects = response.data.result
      })
    }
    if (title.length == 0) {
      this.selected_projects = [];
      return
    }

  }
  get_project_data(data: any, type: any) {
    if (type === 'items') {
      this.items[this.project_index].project = data
    }
    else if (type === 'charges') {
      this.charge_deduct_info[this.project_index].project = data
    }
    else if (type === 'costcenter') {
      this.payload['location'] = data.id
      this.payload['location_title'] = data.title

    }
    else if (type === 'othercost') {
      this.other_cost_info[this.project_index].project = data
      console.log(this.other_cost_info)
    }
    else if (type === 'storage') {
      this.items[this.project_index].storage_bin = data
    }
    this.selected_projects = [];
    this.triggerList = ''
  }
  conditionOptions: any = [
    { value: null, text: 'Please select Items Type' },
    { value: 'Used', text: 'Used' },
    { value: 'New', text: 'New' },
  ]
  addRow() {
    this.items.push({
      item_master: {},
      uom: '',
      qty: '',
      storage_bin: {},
      desc: '',
      unit_rate: '',
      total_excl_vat: '',
      disc_amount: '',
      tax_amount: '',
      total_amount: '',
      net_amount: '',
      project: {},
      drop_items: {},
      item_type: '',
      comment: '',
    })
  }
  deleteRow(index: any) {
    this.items.splice(index, 1);
  }
  clearRow(index: any) {
    const item = this.items[index];
    item.item_master = {}
    item.uom = ''
    item.qty = ''
    item.desc = ''
    item.unit_rate = ''
    item.total_excl_vat = ''
    item.disc_amount = ''
    item.storage_bin = {}
    item.tax_amount = ''
    item.total_amount = ''
    item.net_amount = ''
    item.project = {}
    item.item_type = ''
    item.drop_items={}
    item.comment = ''
  }
  addRowCharges() {
    this.charge_deduct_info.push({
      charges_deduction: '',
      account: {},
      ammount: '',
      charges: '',
      deduction: '',
      project: {},
      comments: '',
    })
  }
  deleteRowCharges(index: any) {
    this.charge_deduct_info.splice(index, 1);
  }
  clearRowCharges(index: any) {
    const item = this.charge_deduct_info[index];
    item.charges_deduction = ''
    item.account = {}
    item.ammount = ''
    item.charges = ''
    item.deduction = ''
    item.project = {}
    item.comments = ''
  }
  addRowCost() {
    this.other_cost_info.push({
      expense: {},
      supplier: {},
      calc: '',
      cost: '',
      project: {},
      comments: '',
    })
  }
  deleteRowCost(index: any) {
    this.other_cost_info.splice(index, 1);
  }
  clearRowCost(index: any) {
    const item = this.other_cost_info[index];
    item.expense = {}
    item.supplier = ''
    item.calc = ''
    item.cost = ''
    item.project = {}
    item.comments = ''
  }
  save() {
    this.error = ""
    const error_list = utils.getEmptyKeys(this.payload, ['voucher_date', 'voucher_no', 'transaction','purchase_acc_code','purchase_acc_title','vat_type'])
    const items_error_list = utils.validateArray(this.items, ['item_master', 'desc','uom','storage_bin','unit_rate','total_excl_vat','disc_amount','tax_amount','total_amount','net_amount','project'])
    const chareges_error_list = utils.validateArray(this.charge_deduct_info, ['ammount','charges','deduction'])
    const cost_error_list = utils.validateArray(this.other_cost_info, [ ])
    const combinedErrors = [...error_list, ...items_error_list, ...chareges_error_list,...cost_error_list];
    if (combinedErrors.length > 0) {
      this.error = utils.generateErrorMessage(combinedErrors)
    }
    else {
      this.payload.receipts_no = this.selected_receipts.receipts_no

    this.payload['items'] = this.items
    this.payload['other_cost_info'] = this.other_cost_info.map((obj:any) => {
    if (obj.expense && typeof obj.expense !== 'object') {
    obj.expense = {
      id: obj.expense,
      title: obj.expense_title
    };
    delete obj.expense_title;
  }

  if (obj.project && typeof obj.project !== 'object') {
    obj.project = {
      id: obj.project,
      title: obj.project_title
    };
    delete obj.project_title;
  }

  if (obj.supplier && typeof obj.supplier !== 'object') {
    obj.supplier = {
      id: obj.supplier,
      title: obj.supplier_title
    };
    delete obj.supplier_title;
  }
  return obj;
  });
    this.payload['charger_deduction'] = this.charge_deduct_info.map((obj:any) => {
    if (obj.account && typeof obj.account !== 'object') {
    obj.account = {
      id: obj.account,
      title: obj.account_title
    };
    delete obj.account_title;
  }

  if (obj.project && typeof obj.project !== 'object') {
    obj.project = {
      id: obj.project,
      title: obj.project_title
    };
    delete obj.project_title;
  }


  return obj;
  });
    purchaseServices.createPurchaseInvoice(this.payload).then((resp) => {
      if (resp.status === 200 || resp.status == 201) {
        purchaseServices.uploadPurchaseInvoiceDoc(this.document_file, resp.data.result.id).then((response) => {
            this.showModal("Succesfully created", [], "success");
            this.reset();
          })
      }
      else {
        this.showModal('An unexpected error occurred', [], "error");
      }
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  retreiveAssetType(id: any) {
    assetsService.getAssetType(id).then((response) => {
      this.assets_type_obj = response.data
    })
  }
  reset(){
    this.payload={}
    this.document_file = null;
    this.clearRow(0);
    this.clearRowCharges(0);
    this.clearRowCost(0);
    for (let i = this.items.length - 1; i > 0; i--) {
      this.deleteRow(i);
    }
    for (let i = this.charge_deduct_info.length - 1; i > 0; i--) {
      this.deleteRowCharges(i);
    }
    for (let i = this.charge_deduct_info.length - 1; i > 0; i--) {
      this.deleteRowCost(i);
    }
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
  getReceipt(receipt_no: string) {
    this.items = []
    this.payload = {}
    purchaseServices.getReceiptRequest(receipt_no).then((resp) => {
      resp.data.purchase_request == undefined ? this.selected_requests = [] : this.selected_requests = resp.data.purchase_request;
      resp.data.purchase_enquiry == undefined ? this.selected_enquiries = [] : this.selected_enquiries = resp.data.purchase_enquiry;
      resp.data.purchase_quotation == undefined ? this.selected_quotations = [] : this.selected_quotations = resp.data.purchase_quotation;
      resp.data.purchase_order == undefined ? this.selected_orders = [] : this.selected_orders = resp.data.purchase_order;
      resp.data.purchase_receipts == undefined ? this.selected_receipts = [] : this.selected_receipts = resp.data.purchase_receipts;
      this.other_cost_info = resp.data.other_cost
      this.charge_deduct_info = resp.data.charges_and_deduction
      // this.other_info = resp.data.purchase_info
      this.payload.executive = this.selected_orders.executive
      this.payload.executive_title = this.selected_orders.executive_title
      this.payload.vat_type = this.selected_orders.vat_type 
      this.payload.location_title = this.selected_requests.cost_center_title
      this.payload.location = this.selected_requests.cost_center
      this.payload.supplier = this.selected_quotations.supplier
      this.payload.supplier_title = this.selected_quotations.supplier_title
      this.payload.supplier_code = this.selected_quotations.supplier_code
      const transaction = {
        id: this.selected_requests.transaction,
        title: this.selected_requests.transaction_title,
        rate: this.selected_requests.transaction_rate,
      }
      this.payload.transaction = transaction
      resp.data.items.forEach((element: any) => {
        let array: any = {}
        array['item_master'] = { 'id': element['item_master'], 'title': element['item_master_title'],'sku':element['item_master_sku'] }
        array['project'] = { 'id': element['project'], 'title': element['project_title'] }
        array['project_title'] = element['project_title']
        array['qty'] = element['qty']
        array['uom'] = element['no_of_pieces']
        array['desc'] = element['desc']
        array['comment'] = element['comment']
        array['storage_bin'] = { 'id': element['storage_bin'], 'title': element['storage_bin_title'] }
        array['unit_rate'] = element['unit_rate']
        array['total_excl_vat'] = element['total_excl_vat']
        array['disc_amount'] = element['disc_amount']
        array['tax_amount'] = element['tax_amount']
        array['total_amount'] = element['total_amount']
        array['net_amount'] = element['net_amount']
        array['item_type'] = element['item_type']
        if(element['item_type'] === 'Assets'){
          array['drop_items'] = { 'assets_item': element['assets_item'],'asset_class': element['assets_item_class'], 'asset_class_type': element['assets_item_class_type'],'division':element['assets_item_divison'],'model':element['assets_item_model'],'registration':element['assets_item_reg'],'title':element['assets_item_title'],'title_arabic':element['assets_item_title'] }
        }
        if(element['item_type'] !== 'Assets'){
          array['drop_items'] = { 'stock_req': element['stock_req'],'stock_item_req': element['stock_item_req'], 'title': element['stock_item_req_title'],'title_arabic':element['stock_item_req_title'],'invoice_no':element['stock_req_invoice_no_delivery'],'po_no':element['stock_req_po_num'],'condition':element['stock_item_req_condition'] }
        }
        this.items.push(array)
      });
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
  }
}
